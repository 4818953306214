import { template as template_235808876a5d4801a8dd018cf174252e } from "@ember/template-compiler";
const WelcomeHeader = template_235808876a5d4801a8dd018cf174252e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
