import { template as template_93c85b79b6f243ddb3235828bb29d1f5 } from "@ember/template-compiler";
const FKText = template_93c85b79b6f243ddb3235828bb29d1f5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
