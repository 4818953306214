import { template as template_a962d3bad7754b5c881f6ab87ca01096 } from "@ember/template-compiler";
const FKLabel = template_a962d3bad7754b5c881f6ab87ca01096(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
